import React from "react"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/Hero"

const BlankTemplate = () => {
  return (
    <Layout>
      <SEO />
      <Hero></Hero>
      {/* <NewsletterSignUp>This needs to Change</NewsletterSignUp> */}
    </Layout>
  )
}

export default BlankTemplate
